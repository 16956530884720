import { IPipelineIndexingData, PipelineServiceLevel } from '@redux/types/types';
import { PipelineStatusCodes } from '@src/constants/pipelines';

export const pipelineInProgress = (status: PipelineStatusCodes) =>
  status === PipelineStatusCodes.IN_PROGRESS ||
  status === PipelineStatusCodes.UNDEPLOYMENT_IN_PROGRESS ||
  status === PipelineStatusCodes.UNDEPLOYMENT_SCHEDULED ||
  status === PipelineStatusCodes.DEPLOYMENT_IN_PROGRESS ||
  status === PipelineStatusCodes.DEPLOYMENT_SCHEDULED ||
  status === PipelineStatusCodes.ACTIVATING;

export const isPipelineDeployed = (status: PipelineStatusCodes) =>
  status === PipelineStatusCodes.IDLE ||
  status === PipelineStatusCodes.DEPLOYED ||
  status === PipelineStatusCodes.UNDEPLOYMENT_IN_PROGRESS ||
  status === PipelineStatusCodes.UNDEPLOYMENT_SCHEDULED;

export const isPipelineInProduction = (
  status: PipelineStatusCodes,
  serviceLevel: PipelineServiceLevel,
) =>
  (isPipelineDeployed(status) || pipelineInProgress(status)) &&
  serviceLevel === PipelineServiceLevel.PRODUCTION;

export const getPipelineIdleTimeoutLabel = (pipelineTimeout: number | null) => {
  const idleTimeoutInHours = pipelineTimeout ? pipelineTimeout / 60 / 60 : 0;

  if (idleTimeoutInHours <= 24) {
    return `${idleTimeoutInHours} hours`;
  }
  if (idleTimeoutInHours > 24) {
    const days = Math.floor(idleTimeoutInHours / 24);
    const hours = idleTimeoutInHours % 24;
    if (hours === 0) {
      return `${days} days`;
    }
    return `${days} days and ${hours} hours`;
  }
  return '';
};

export const isPipelineIndexed = (indexingData: IPipelineIndexingData) => {
  return indexingData.indexed_file_count > 0 && indexingData.pending_file_count === 0;
};
