import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined, FilterOutlined, UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Drawer, Empty, Space } from 'antd';
import { isEmpty, isEqual } from 'lodash';
import { getFileExtension, readableBytes } from '@utils/common';
import { interpolateString } from '@utils/string';
import { uploadFilesApi } from '@api/upload';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import { DOCUMENTATION_LINK_LABEL, VIEW_METADATA_BUTTON_LABEL } from '@constants/common';
import { UploadFileType } from '@constants/constant';
import {
  DECLINE_BUTTON_LABEL,
  DEFAULT_PAGE_SIZE,
  DELETE_ALL_CONFIRMATION_MESSAGE,
  DELETE_ALL_FILES_BUTTON_LABEL,
  DELETE_BUTTON_LABEL,
  DELETE_FILE_CONFIRMATION_MESSAGE,
  DELETE_SELECTED_FILE_MESSAGE,
  DELETE_SELECTED_FILES_PLURAL_LABEL,
  DELETE_SELECTED_FILES_SINGULAR_LABEL,
  DOWNLOAD_BUTTON_LABEL,
  EMPTY_FILES_PAGE_SECTION,
  EMPTY_FILES_TABLE_MESSAGE,
  FILE_SORTING_DATATABLE_OPTIONS,
  METADATA_FILTERS_APPLY_LABEL,
  METADATA_FILTERS_CLEAR_ALL_LABEL,
  METADATA_FILTERS_LABEL,
  PREVIEW_FILE_BUTTON_LABEL,
  PREVIEW_FILE_DRAWER_TITLE,
  UPLOAD_BUTTON_LABEL,
  UPLOAD_DESCRIPTION_MESSAGE,
  UPLOAD_FILE_BUTTON_LABEL,
  UPLOAD_FILES_DOCS_URI,
} from '@constants/data-page';
import { SelectedFilters } from '@constants/data-table';
import { StatusCodes } from '@constants/enum/common';
import {
  deleteAllFiles,
  deleteFile,
  deleteMultipleFiles,
  downloadFile,
  getFileContent,
  getWorkspaceFiles,
  resetFileContent,
  resetFilesMessage,
  selectFileDocument,
  updateFileMeta as updateFileMetaAction,
} from '@redux/actions/fileActions';
import {
  getPipelineMeta,
  selectMetaDataFilterValues,
  setAppliedMetaDataFilterValues,
} from '@redux/actions/metadataFiltersActions';
import {
  fileActionStatusSelector,
  fileContentSelector,
  fileFetchStatusSelector,
  fileMessageSelector,
  filesSelector,
  getFileContentStatusSelector,
  selectedFileDocumentSelector,
  sortValueSelector,
  updateFileMetaStatusSelector,
} from '@redux/selectors/fileSelectors';
import {
  appliedMetaFilterValuesSelector,
  selectedMetaFilterValuesSelector,
} from '@redux/selectors/metadataFiltersSelectors';
import { IMessage, MetadataFiltersType } from '@redux/types/types';
import LoadingIndicator from '@components/common/LoadingIndicator/LoadingIndicator';
import DataTable from '@components/dataTable/DataTable';
import DataTableActions from '@components/dataTable/DataTableActions';
import EmptyPageDescription from '@components/emptyPageDescription/EmptyPageDescription';
import MetadataFilters from '@components/metadataFilters/MetadataFilters';
import MetadataModal from '@components/metadataModal/MetadataModal';
import UploadFileModal from '@components/uploadModal/UploadFileModal';
import styles from './filesPage.module.scss';

const FilesLandingSVG = React.lazy(() => import('@assets/empty/files-landing.svg?react'));
const DocumentsPreview = React.lazy(() => import('@components/documentsPreview/DocumentsPreview'));

const PIPELINE_NAME_FOR_METADATA_ON_FILES_PAGE = 'workspace-pipeline';

enum MenuActions {
  ViewMetadata = 'VIEW_METADATA',
  Delete = 'DELETE',
  Download = 'DOWNLOAD',
}

export interface IGetFilesPayload {
  currentPage: number;
  pageSize: number;
  searchValue: string;
  sortValue?: string;
  filterValues?: SelectedFilters;
  after?: Record<string, unknown>;
}

const FilesPage = () => {
  const dispatch = useDispatch();
  const message: IMessage = useSelector(fileMessageSelector);
  const actionStatus: StatusCodes = useSelector(fileActionStatusSelector);
  const fetchStatus: StatusCodes = useSelector(fileFetchStatusSelector);
  const { data, total }: any = useSelector(filesSelector);
  const selectedFileDocument = useSelector(selectedFileDocumentSelector);
  const fileContent = useSelector(fileContentSelector);
  const getFileContentStatus = useSelector(getFileContentStatusSelector);
  const selectedSortValue: string = useSelector(sortValueSelector);
  const selectedMetaFilterValues = useSelector(selectedMetaFilterValuesSelector);
  const appliedMetaFilterValues = useSelector(appliedMetaFilterValuesSelector);
  const updateFileMetaStatus = useSelector(updateFileMetaStatusSelector);
  const [fileToDelete, setFileToDelete] = useState('');
  const [totalSelectedItems, setTotalSelectedItems] = useState(0);
  const [isUploadModalVisible, setIsUploadModalVisibleTo] = useState(false);
  const [isMetaModalVisible, setIsMetaModalVisible] = useState(false);
  const [currentModalMetaData, setCurrentModalMetaData] = useState<Record<string, unknown>>({});
  const [openMetadataFilters, setOpenMetadataFilters] = useState(false);
  const [cleanedMetaFilterValues, setCleanedMetaFilterValues] = useState(false);
  const [getFilesWithMetadataFiltersPayload, setGetFilesWithMetadataFiltersPayload] =
    useState<IGetFilesPayload>({
      currentPage: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      searchValue: '',
    });
  const filesDataTableParams = useRef<{
    currentPage: number;
    pageSize: number;
    searchValue: string;
    sortValue?: string;
    filterValues?: SelectedFilters;
    after?: Record<string, unknown>;
    metadataFilterValues?: Record<string, MetadataFiltersType>;
  } | null>(null);

  const loading =
    actionStatus === StatusCodes.IN_PROGRESS || fetchStatus === StatusCodes.IN_PROGRESS;
  const thereAreNoFiles = fetchStatus === StatusCodes.SUCCESS && data.length === 0;

  useEffect(() => {
    dispatch(resetFileContent);
    dispatch(resetFilesMessage);
    dispatch(selectMetaDataFilterValues({}));
    dispatch(setAppliedMetaDataFilterValues({}));
    dispatch(getPipelineMeta({ pipelineName: PIPELINE_NAME_FOR_METADATA_ON_FILES_PAGE }));
  }, []);

  useEffect(() => {
    if (openMetadataFilters) dispatch(selectMetaDataFilterValues(appliedMetaFilterValues));
  }, [openMetadataFilters]);

  const thereAreActiveFilters = () =>
    !!Object.values(filesDataTableParams.current?.filterValues || {}).flat().length ||
    !!Object.values(filesDataTableParams.current?.metadataFilterValues || {}).flat().length ||
    !!filesDataTableParams.current?.searchValue;

  const getFiles = (
    currentPage: number,
    pageSize: number,
    searchValue: string,
    sortValue?: string,
    filterValues?: SelectedFilters,
    after?: Record<string, unknown>,
    metadataFilterValues?: Record<string, MetadataFiltersType>,
  ) => {
    const afterValue = { value: '', fileId: '' };
    if (after) {
      if ((sortValue && sortValue.includes('name')) || selectedSortValue.includes('name'))
        afterValue.value = after.name as string;
      else afterValue.value = after.created_at as string;
      afterValue.fileId = after.file_id as string;
    }
    const params = {
      currentPage,
      pageSize,
      searchValue,
      sortValue,
      filterValues,
      after: after ? afterValue : undefined,
      metadataFilterValues,
    };

    filesDataTableParams.current = {
      ...params,
      after, // TODO: Standarize 'after' param
    };
    return dispatch(getWorkspaceFiles(params));
  };

  const getFileById = (fileId: string) => {
    return data.find((file: any) => file.file_id === fileId);
  };

  const getFileMetadataById = (fileId: string) => {
    const { meta } = getFileById(fileId) || {};
    return { ...meta, file_id: fileId };
  };

  const updateFileMeta = (fileId: string, updateMeta: Record<string, unknown>) => {
    dispatch(updateFileMetaAction({ fileId, meta: updateMeta }));
  };

  useEffectUpdateOnly(() => {
    if (!selectedFileDocument || !fileContent) return;
    if (getFileContentStatus === StatusCodes.SUCCESS) {
      dispatch(selectFileDocument({ ...selectedFileDocument, contents: [{ value: fileContent }] }));
    }
  }, [getFileContentStatus]);

  useEffectUpdateOnly(() => {
    if (updateFileMetaStatus === StatusCodes.SUCCESS) {
      const {
        currentPage = 1,
        pageSize = DEFAULT_PAGE_SIZE,
        searchValue = '',
        sortValue,
        filterValues,
        after,
        metadataFilterValues,
      } = filesDataTableParams.current || {};
      getFiles(
        currentPage,
        pageSize,
        searchValue,
        sortValue,
        filterValues,
        after,
        metadataFilterValues,
      );
      setIsMetaModalVisible(false);
    }
  }, [updateFileMetaStatus]);

  const showApplyMetadataFiltersButton = () => {
    return !isEqual(selectedMetaFilterValues, appliedMetaFilterValues);
  };

  const showClearAllMetadataFiltersButton = () => {
    return !isEmpty(selectedMetaFilterValues) || !isEmpty(appliedMetaFilterValues);
  };

  // TODO: Remove this when preview for other types is implemented
  const isValidFileFormatForPreview = (file: File | string) => {
    const fileType = getFileExtension(file);
    const supportedFileTypes = [
      UploadFileType.txt,
      UploadFileType.pdf,
      UploadFileType.json,
      UploadFileType.html,
      UploadFileType.md,
      UploadFileType.xml,
      UploadFileType.csv,
      UploadFileType.docx,
    ];

    return supportedFileTypes.includes(fileType as UploadFileType);
  };

  const onViewMetadata = (fileId: string) => {
    setIsMetaModalVisible(true);
    setCurrentModalMetaData(getFileMetadataById(fileId));
  };

  const openDocumentPreview = async (fileId: string, fileName: string) => {
    dispatch(selectFileDocument({ contents: [], fileName, fileId }));
    dispatch(getFileContent({ fileId, fileName }));
  };

  const closeDocumentPreview = () => {
    dispatch(selectFileDocument(null));
  };

  const onDeleteFile = async (fileId: string) => {
    dispatch(deleteFile(fileId));
  };

  const onDeleteMultipleFiles = async (fileIds: string[]) => {
    dispatch(deleteMultipleFiles(fileIds));
  };

  const onDeleteAllFiles = async () => {
    await dispatch(deleteAllFiles());
  };

  const onUpload = () => {
    setIsUploadModalVisibleTo(true);
  };

  const applyMetadataFilters = (metadataFiltersExists: boolean) => {
    const { currentPage, pageSize, searchValue, sortValue, filterValues } =
      getFilesWithMetadataFiltersPayload;
    getFiles(
      currentPage,
      pageSize,
      searchValue,
      sortValue,
      filterValues,
      undefined,
      metadataFiltersExists ? selectedMetaFilterValues : undefined,
    );
  };

  const onClearAllFilters = () => {
    dispatch(selectMetaDataFilterValues({}));
    dispatch(setAppliedMetaDataFilterValues({}));
    applyMetadataFilters(false);
    setCleanedMetaFilterValues(true);
  };

  const onClickApplyMetadataFilters = async () => {
    dispatch(setAppliedMetaDataFilterValues(selectedMetaFilterValues));
    applyMetadataFilters(true);
    setCleanedMetaFilterValues(false);
  };

  const onOpenMetadataFilters = (payload: IGetFilesPayload) => {
    setGetFilesWithMetadataFiltersPayload({ ...payload });
    setOpenMetadataFilters(true);
  };

  const handleMoreActionClick = (key: string, fileName: string, fileId: string) => {
    if (key === MenuActions.ViewMetadata) onViewMetadata(fileId);
    if (key === MenuActions.Delete) setFileToDelete(fileId);
    if (key === MenuActions.Download) dispatch(downloadFile({ fileId, fileName }));
  };

  // Renders

  const renderMetaDataModal = () => {
    return (
      <MetadataModal
        data={currentModalMetaData}
        nonEditableFields={['file_id']}
        updating={updateFileMetaStatus === StatusCodes.IN_PROGRESS}
        open={isMetaModalVisible}
        onEdit={(updateMeta: Record<string, unknown>) =>
          updateFileMeta(currentModalMetaData.file_id as any, updateMeta)
        }
        onOk={() => setIsMetaModalVisible(false)}
        onCancel={() => setIsMetaModalVisible(false)}
      />
    );
  };

  const renderMetadataFilters = () => {
    return (
      <Drawer
        title={METADATA_FILTERS_LABEL}
        onClose={() => setOpenMetadataFilters(false)}
        open={openMetadataFilters}
        extra={
          <Space>
            {showClearAllMetadataFiltersButton() && (
              <Button onClick={() => onClearAllFilters()}>
                {METADATA_FILTERS_CLEAR_ALL_LABEL}
              </Button>
            )}
            {showApplyMetadataFiltersButton() && (
              <Button onClick={() => onClickApplyMetadataFilters()} type="primary">
                {METADATA_FILTERS_APPLY_LABEL}
              </Button>
            )}
          </Space>
        }
        className={styles.metadataFiltersDrawer}
      >
        <MetadataFilters pipelineName={PIPELINE_NAME_FOR_METADATA_ON_FILES_PAGE} showAll />
      </Drawer>
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string, record: any) => (
        <Button
          type="link"
          size="small"
          className={styles.nameLink}
          onClick={() => {
            if (isValidFileFormatForPreview(record)) openDocumentPreview(record.file_id, name);
          }}
        >
          {name}
        </Button>
      ),
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      width: '10%',
      render: (size: number) => readableBytes(size),
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '20%',
      render: (created_at: string) => new Date(created_at).toLocaleString(),
    },
    {
      key: 'action',
      width: '10%',
      align: 'right' as const,
      render: (text: any, record: any) => {
        const items = [
          {
            label: VIEW_METADATA_BUTTON_LABEL,
            key: MenuActions.ViewMetadata,
          },
          {
            label: DOWNLOAD_BUTTON_LABEL,
            key: MenuActions.Download,
          },
          {
            label: DELETE_BUTTON_LABEL,
            key: MenuActions.Delete,
            danger: true,
            icon: <DeleteOutlined />,
          },
        ];
        return (
          <DataTableActions
            menu={{
              items,
              onClick: ({ key }) => handleMoreActionClick(key, record.name, record.file_id),
              'data-testid': 'files_tableRow_moreActions_button',
            }}
            item={record.file_id}
            itemToDelete={fileToDelete}
            onDelete={onDeleteFile}
            deleteConfirmationMessage={DELETE_FILE_CONFIRMATION_MESSAGE}
            cancelButtonLabel={DECLINE_BUTTON_LABEL}
            onCancelDelete={() => setFileToDelete('')}
            primaryButton={{
              label: PREVIEW_FILE_BUTTON_LABEL,
              action: (fileId: string) => openDocumentPreview(fileId, record.name),
              visible: isValidFileFormatForPreview(record),
            }}
          />
        );
      },
    },
  ];

  const renderEmptyFilesState = () => {
    return (
      <EmptyPageDescription
        title={EMPTY_FILES_PAGE_SECTION.TITLE}
        description={EMPTY_FILES_PAGE_SECTION.DESCRIPTION.map(({ paragraph }) => ({
          paragraph: interpolateString(paragraph, {
            documentationLink: (
              <a href={UPLOAD_FILES_DOCS_URI} target="_blank" rel="noreferrer">
                {DOCUMENTATION_LINK_LABEL}
              </a>
            ),
          }),
        }))}
        image={<FilesLandingSVG />}
        action={
          <Button type="primary" onClick={onUpload} icon={<UploadOutlined />}>
            {UPLOAD_FILE_BUTTON_LABEL}
          </Button>
        }
      />
    );
  };

  const renderFilesListTable = () => {
    const emptyFilesTableMessage = interpolateString(EMPTY_FILES_TABLE_MESSAGE, {
      uploadFilesButton: (
        <Button key="uploadFile" type="link" onClick={onUpload} style={{ padding: 0 }}>
          {UPLOAD_BUTTON_LABEL}
        </Button>
      ),
    });

    return (
      <DataTable
        data={data}
        total={total}
        loading={loading}
        testId="filesTable"
        refetch={actionStatus === StatusCodes.SUCCESS}
        columns={columns}
        getData={getFiles}
        sorting={{
          selectedValue: selectedSortValue,
          options: FILE_SORTING_DATATABLE_OPTIONS,
        }}
        pagination={{ pageSize: DEFAULT_PAGE_SIZE, cursorPagination: true }}
        primaryAction={{
          label: UPLOAD_FILE_BUTTON_LABEL,
          onClick: onUpload,
          testid: 'filesPage_uploadFile_button',
          icon: <UploadOutlined />,
        }}
        filtersAction={{
          secondary: true,
          onClick: (payload?: IGetFilesPayload) => {
            if (payload) onOpenMetadataFilters(payload);
          },
          filtersApplied: !isEmpty(appliedMetaFilterValues),
          testid: 'filesPage_metadataFilters_button',
          icon: <FilterOutlined />,
          tooltipMessage: METADATA_FILTERS_LABEL,
        }}
        appliedMetaFilterValues={appliedMetaFilterValues}
        cleanedMetaFilterValues={cleanedMetaFilterValues}
        locale={{
          emptyText: (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyFilesTableMessage} />
          ),
        }}
        rowKey="file_id"
        selectActions={[
          {
            type: 'default',
            danger: true,
            label: (totalSelectedItems === 1
              ? interpolateString(DELETE_SELECTED_FILES_SINGULAR_LABEL, {
                  total: totalSelectedItems,
                })
              : interpolateString(DELETE_SELECTED_FILES_PLURAL_LABEL, {
                  total: totalSelectedItems,
                })) as string,
            onClick: onDeleteMultipleFiles,
            popconfirm: {
              title: DELETE_SELECTED_FILE_MESSAGE,
              cancelText: DECLINE_BUTTON_LABEL,
            },
          },
          {
            type: 'default',
            danger: true,
            label: DELETE_ALL_FILES_BUTTON_LABEL,
            onClick: onDeleteAllFiles,
            popconfirm: {
              title: DELETE_ALL_CONFIRMATION_MESSAGE,
              cancelText: DECLINE_BUTTON_LABEL,
            },
          },
        ]}
        setTotalSelectedItems={(totalFilesSelected) => setTotalSelectedItems(totalFilesSelected)}
      />
    );
  };

  return (
    <div data-testid="filesPage" className="content-wrapper">
      {message && message.content && <Alert message={message.content} type={message.type} banner />}
      {thereAreNoFiles && !thereAreActiveFilters()
        ? renderEmptyFilesState()
        : renderFilesListTable()}
      {renderMetaDataModal()}
      {isUploadModalVisible && (
        <UploadFileModal
          multiple
          fileTypes={[
            UploadFileType.txt,
            UploadFileType.pdf,
            UploadFileType.docx,
            UploadFileType.pptx,
            UploadFileType.xlsx,
            UploadFileType.xml,
            UploadFileType.csv,
            UploadFileType.html,
            UploadFileType.md,
            UploadFileType.json,
          ]}
          showUploadList={false}
          onUpload={uploadFilesApi}
          onCancel={() => setIsUploadModalVisibleTo(false)}
          afterUpload={() => getFiles(1, 10, '')}
          uploadDescriptionMessage={UPLOAD_DESCRIPTION_MESSAGE}
          showFileSizeLimitMessage
        />
      )}
      {renderMetadataFilters()}
      {selectedFileDocument && (
        <React.Suspense fallback={<LoadingIndicator />}>
          <DocumentsPreview
            title={PREVIEW_FILE_DRAWER_TITLE}
            contents={selectedFileDocument.contents}
            closeDocumentPreview={closeDocumentPreview}
            source={selectedFileDocument.fileName}
            meta={getFileMetadataById(selectedFileDocument.fileId || '')}
            onUpdateFileMeta={(updateMeta) =>
              updateFileMeta(selectedFileDocument.fileId, updateMeta)
            }
            loading={getFileContentStatus === StatusCodes.IN_PROGRESS}
            isUpdatingFileMeta={updateFileMetaStatus === StatusCodes.IN_PROGRESS}
          />
        </React.Suspense>
      )}
    </div>
  );
};

export default FilesPage;
