import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import {
  BookOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  QuestionCircleOutlined,
  QuestionOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Divider, Dropdown, Layout, Menu, MenuProps, Popover, Tag } from 'antd';
import { TourStepProps } from 'antd/lib';
import { formatTestId } from '@utils/test';
import DeepsetLogoSVG from '@assets/deepset-cloud-logo-darkblue.svg?react';
import { useCurrentRoute } from '@hooks/useCurrentRoute';
import useFeatureTour from '@hooks/useFeatureTour';
import { BETA_LABEL, HELP_LABEL, NEW_LABEL } from '@constants/common';
import {
  CONTACT_SUPPORT_MODAL_SUBMIT_BUTTON_LABEL,
  CONTACT_SUPPORT_MODAL_TITLE,
  HELP_OPTIONS,
} from '@constants/contact-support';
import { UserRoles } from '@constants/enum/common';
import { SIDEBAR_MENU_ITEMS, WORKSPACE_LABEL } from '@constants/sidebar-menu';
import { MAX_WORKSPACES, ORGANIZATIONS_ID } from '@constants/workspace';
import { updateCollapsed, updateOpenKeys } from '@redux/actions/layoutActions';
import { createWorkspace, getWorkspaces, setWorkspace } from '@redux/actions/organizationActions';
import { layoutSidebarSelector } from '@redux/selectors/layoutSelectors';
import { workspaceSelector } from '@redux/selectors/organizationSelectors';
import { userSelector } from '@redux/selectors/userSelectors';
import { IUserData } from '@redux/types/types';
import UserMenu from '@components/common/UserMenu/UserMenu';
import ContactSupportModal from '@components/contactSupportModal/ContactSupportModal';
import DocsModel from '@components/docsChat/docsChat';
import NewFeatureTour from '@components/newFeatureTour/NewFeatureTour';
import NewWorkspaceMenuItem from '@components/newWorkspaceMenuItem/NewWorkspaceMenuItem';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import styles from './layoutWrapper.module.scss';

const SIDE_BAR_SUBTITLE_ELEMENT_PREFIX = 'subtitle';

enum LayoutSearchParams {
  embed = 'embed',
}

const LayoutWrapper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    sideBar: { activeKey: currentRouteKey },
    withSidebar = true,
    path: currentRoutePath,
    header: {
      title: headerTitle,
      backButtonLink: headerBackButtonLink,
      withUserMenu: headerWithUserMenu = true,
      rightSideButton: headerRightSideButton,
      withSmallContainer: headerWithSmallContainer,
      crumbs: headerCrumbs,
    },
    searchParams,
  } = useCurrentRoute();
  const [newWorkspaceName, setNewWorkspaceName] = useState('');
  const { role, organization }: IUserData = useSelector(userSelector);
  const { openKeys, collapsed }: any = useSelector(layoutSidebarSelector);
  const { currentWorkspace, currentWorkspaceId, workspaces }: any = useSelector(workspaceSelector);

  const { Header, Content, Sider } = Layout;

  // TODO: Improve displaying content by role when new roles are added - https://github.com/deepset-ai/haystack-hub-ui/issues/2431
  const isAdminUser = role === UserRoles.ADMIN;
  const isEmbedded = !!searchParams[LayoutSearchParams.embed];
  const isSidebarVisible = withSidebar && !isEmbedded;
  const showTopLogo = !withSidebar;
  const showBackButton = isAdminUser && headerBackButtonLink;

  // Tour
  const { tourDismissed: navigationTourDismissed, setTourDismissed: setNavigationTourDismissed } =
    useFeatureTour('navigationTour');
  const [navigationTourSteps, setNavigationTourSteps] = useState<TourStepProps[]>([]);
  const [openDocumentation, setOpenDocumentation] = useState(false);
  const [openContactSupportModal, setOpenContactSupportModal] = useState(false);
  const pipelineTemplatesMenuItemRef = useRef<HTMLDivElement>(null);
  const playgroundMenuItemRef = useRef<HTMLDivElement>(null);
  const groundednessMenuItemRef = useRef<HTMLDivElement>(null);
  const helpButtonRef = useRef<HTMLButtonElement>(null);

  const [searchParamsUrl] = useSearchParams();
  const enableSupportButton = !!searchParamsUrl.get('withSupportButton');

  const getNavigationTourSteps = (): TourStepProps[] => {
    if (!isSidebarVisible) return [];

    // Add new tour steps here when needed
    return [];
  };

  useEffect(() => {
    if (pipelineTemplatesMenuItemRef.current) setNavigationTourSteps(getNavigationTourSteps());
  }, [pipelineTemplatesMenuItemRef.current]);

  function handleLogoClick() {
    navigate('/');
  }

  const setCurrentWorkspace = (workspace: string) => {
    dispatch(setWorkspace(workspace));
    navigate('/');
  };

  const handleAddWorkspace = async () => {
    await dispatch(createWorkspace(newWorkspaceName));
    dispatch(getWorkspaces());
    setNewWorkspaceName('');
    setCurrentWorkspace(newWorkspaceName);
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setNewWorkspaceName(event.currentTarget.value);
  };

  const onClickSideBarItem = (link: string) => {
    if (link === currentRoutePath) return;
    navigate(link);
  };

  const triggerCollapse = () => {
    dispatch(updateCollapsed(!collapsed));
  };

  function getSidebarMenuItems() {
    // TODO: Add an ID to sidebar menu items
    const mappedRefByLabel = {
      'Pipeline Templates': pipelineTemplatesMenuItemRef,
      Playground: playgroundMenuItemRef,
      Groundedness: groundednessMenuItemRef,
    };

    return SIDEBAR_MENU_ITEMS.map((item) => {
      const { icon, label, userAccess, link, newLabel, betaLabel } = item;
      const IconComponent = icon;

      if (!link)
        return {
          label: collapsed ? (
            <Divider className={styles.sidebar_divider} />
          ) : (
            <div className={styles.sidebar_subtitle}>{label}</div>
          ),
          key: `${SIDE_BAR_SUBTITLE_ELEMENT_PREFIX}-${label}`,
          title: '',
          tooltip: null,
          icon: icon ? <IconComponent style={{ fontSize: 18 }} /> : null,
        };

      return {
        key: label,
        label: (
          <Link to={link}>
            <div>
              {label}
              {newLabel && !collapsed && (
                <Tag color="success" className={styles.sidebar_subtitle_tag}>
                  {NEW_LABEL}
                </Tag>
              )}
              {betaLabel && !newLabel && !collapsed && (
                <Tag color="warning" className={styles.sidebar_subtitle_tag}>
                  {BETA_LABEL}
                </Tag>
              )}
            </div>
          </Link>
        ),
        onClick: () => onClickSideBarItem(link!),
        disabled: !userAccess?.includes(role),
        icon: (
          <IconComponent
            style={{ fontSize: 18 }}
            ref={mappedRefByLabel[label as 'Pipeline Templates' | 'Playground' | 'Groundedness']}
          />
        ),
        'data-testid': formatTestId('sidebar', label),
      };
    });
  }

  const onOpenChange = (key: string[]) => {
    dispatch(updateOpenKeys(key));
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <span>
          <BookOutlined />
          {HELP_OPTIONS.documentation.label}
        </span>
      ),
      key: HELP_OPTIONS.documentation.key,
      onClick: () => setOpenDocumentation(true),
    },
    {
      label: (
        <span>
          <QuestionOutlined />
          {HELP_OPTIONS.contactSupport.label}
        </span>
      ),
      key: HELP_OPTIONS.contactSupport.key,
      onClick: () => setOpenContactSupportModal(true),
    },
  ];

  // Renders

  const renderWorkspace = () => {
    const newWorkspaceItem = {
      key: 'newWorkspace',
      disabled: true,
      className: styles.newWorkspaceItem,
      label: (
        <NewWorkspaceMenuItem
          value={newWorkspaceName}
          onChange={onNameChange}
          onAdd={handleAddWorkspace}
          data-testid="sidebar_newWorkspace_component"
        />
      ),
    };
    const workspaceItems = workspaces.map((workspace: any) => ({
      label: workspace.name,
      key: workspace.workspace_id,
      onClick: () => setCurrentWorkspace(workspace.name),
      'data-testid': formatTestId('sidebar_workspace', workspace.name),
    }));
    const menuItems = [
      {
        label: collapsed ? null : (
          <div className={styles.workspace_info}>
            <span className={styles.workspace_info_title}>{currentWorkspace}</span>
            <span className={styles.workspace_info_label}>{WORKSPACE_LABEL}</span>
          </div>
        ),
        key: currentWorkspace,
        className: collapsed ? styles.subMenu : '',
        'data-testid': 'sidebar_currentWorkspace_label',
        children: [
          ...workspaceItems,
          ...(isAdminUser &&
          workspaces.length <
            (ORGANIZATIONS_ID.includes(organization) ? MAX_WORKSPACES * 2 : MAX_WORKSPACES)
            ? [newWorkspaceItem]
            : []),
        ],
      },
    ];

    return (
      <section className={`${styles.workspace} ${collapsed ? styles.collapsed : ''}`}>
        <Menu
          className={styles.workspace_select}
          triggerSubMenuAction="click"
          selectedKeys={[currentWorkspaceId]}
          expandIcon={() =>
            collapsed ? (
              <Avatar
                size="small"
                className={`${styles.workspaceButton} ${styles.collapsed}`}
                data-testid="sidebar_expandWorkspaces_button"
              >
                {currentWorkspace[0]}
                {currentWorkspace[1]}
              </Avatar>
            ) : (
              <SwapOutlined
                className={styles.workspaceButton}
                data-testid="sidebar_expandWorkspaces_button"
              />
            )
          }
          items={menuItems}
        />
      </section>
    );
  };

  function renderLogo(
    { withBackgroundColor }: { withBackgroundColor: boolean } = { withBackgroundColor: true },
  ) {
    const Logo = () => (collapsed ? null : <DeepsetLogoSVG className={styles.logo} />);

    return (
      <div
        className={`${styles.logoWrapper} ${
          withBackgroundColor ? styles.logoWrapper_withBackground : ''
        } ${collapsed ? styles.collapsed : ''}`}
      >
        <div
          role="button"
          className={styles.logoButton}
          tabIndex={0}
          onClick={() => handleLogoClick()}
          onKeyDown={() => handleLogoClick()}
        >
          <Logo />
        </div>
        {isSidebarVisible && (
          <Button
            size="small"
            onClick={() => triggerCollapse()}
            className={`${styles.collapseButton} ${collapsed ? styles.collapsed : ''}`}
            icon={collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
          />
        )}
      </div>
    );
  }

  const renderSidebar = () => {
    if (!isSidebarVisible) return null;

    return (
      <Sider
        className={styles.sidebar}
        width={240}
        collapsedWidth={56}
        trigger={null}
        collapsed={collapsed}
        data-testid="sidebar"
      >
        <div>
          {renderWorkspace()}
          <Menu
            className={styles.sidebar_menu}
            selectedKeys={[currentRouteKey || '']}
            openKeys={openKeys}
            onOpenChange={(key) => onOpenChange(key)}
            defaultSelectedKeys={['Home']}
            mode="inline"
            inlineIndent={8}
            items={getSidebarMenuItems()}
          />
        </div>
        {renderLogo()}
      </Sider>
    );
  };

  return (
    <div className={styles.contentWrapper}>
      <Layout className={styles.mainLayout}>
        {renderSidebar()}
        <Layout className={styles.contentApp}>
          {!isEmbedded && (
            <Header
              className={`${styles.header} ${headerWithSmallContainer ? styles.headerSmall : ''}`}
            >
              {showTopLogo ? renderLogo({ withBackgroundColor: false }) : null}

              <div className={styles.pageTitle}>
                {headerCrumbs && <Breadcrumbs />}
                {headerTitle && !headerCrumbs && (
                  <>
                    {showBackButton && (
                      <Button
                        icon={<LeftOutlined />}
                        size="small"
                        type="text"
                        onClick={() =>
                          typeof headerBackButtonLink === 'number'
                            ? navigate(headerBackButtonLink as number)
                            : navigate(headerBackButtonLink)
                        }
                        className={styles.pageTitle_backButton}
                      />
                    )}
                    <div className={styles.pageTitle_container}>
                      <h2 className={styles.pageTitle_header}>{headerTitle}</h2>
                    </div>
                  </>
                )}
              </div>
              {headerWithUserMenu && (
                <>
                  {enableSupportButton && !openDocumentation ? (
                    <Dropdown menu={{ items }} placement="bottomRight" trigger={['click']}>
                      <Button size="small" icon={<QuestionCircleOutlined />} ref={helpButtonRef}>
                        {HELP_LABEL}
                      </Button>
                    </Dropdown>
                  ) : (
                    <Popover
                      rootClassName={styles.popOver}
                      arrow={false}
                      content={<DocsModel />}
                      trigger="click"
                      open={openDocumentation}
                      onOpenChange={(open) => setOpenDocumentation(open)}
                      placement="bottomRight"
                    >
                      <Button size="small" icon={<QuestionCircleOutlined />} ref={helpButtonRef}>
                        {HELP_LABEL}
                      </Button>
                    </Popover>
                  )}

                  <UserMenu />
                </>
              )}

              {headerRightSideButton && (
                <Button
                  className={styles.header_rightSideButton}
                  type={headerRightSideButton.type}
                  onClick={() =>
                    headerRightSideButton.redirectPath &&
                    navigate(headerRightSideButton.redirectPath)
                  }
                >
                  {headerRightSideButton.text}
                </Button>
              )}
            </Header>
          )}
          <Content id="contentAppSection">
            <Outlet />
          </Content>
        </Layout>
        {!!navigationTourSteps.length && isAdminUser && (
          <NewFeatureTour
            openTour={!navigationTourDismissed}
            steps={navigationTourSteps}
            placement="left"
            onClose={setNavigationTourDismissed}
          />
        )}
        <ContactSupportModal
          title={CONTACT_SUPPORT_MODAL_TITLE}
          okButtonText={CONTACT_SUPPORT_MODAL_SUBMIT_BUTTON_LABEL}
          open={openContactSupportModal}
          setOpenContactSupportModal={setOpenContactSupportModal}
        />
      </Layout>
    </div>
  );
};

export default LayoutWrapper;
